* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  background: #130d33;
  overflow-x: hidden;
  font-family: "Poppins-Regular";
}
img {
  max-width: 100%;
}
a {
  text-decoration: none;
  color: white;
  cursor: pointer;
  display: inline-block;
}

body {
  color: white;
}
