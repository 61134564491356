//BRAND COLORS
$Dark-pruple: #130d33;
$Blue: #55d6fd;
$Purple: #1d134c;
$Yellow: #ffec82;
$Pink: #ee84b2;
$Red: #d3294b;
$White: #ffff;

.btn {
  padding: 10px 20px;
  border-radius: 4px;
  outline: none;
  border: none;
  min-width: 100px;
  cursor: pointer;
  white-space: nowrap;
  font-family: "Poppins-SemiBold";
  font-size: 1rem;
}
.btn-link {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  padding: 8px 16px;
  height: 100%;
  width: 100%;
}

/*STYLES*/
.btn--primary {
  background-color: $Pink;
  color: #242424;
  border: 1px solid $Pink;
}

.btn--outline {
  background-color: transparent;
  padding: 8px 20px;
  color: $White;
  border: 1px solid $Pink;
  transition: all 0.3s ease-out;
}

/*SIZING*/
.btn--medium {
  padding: 8px 20px;
}

.btn--large {
  padding: 11px 26px;
}

.btn--mobile {
  text-align: center;
  border-radius: 4px;
  width: 80%;
  text-decoration: none;

  background-color: transparent;
  padding: 14px 20px;
  color: $White;
  border: 1px solid $Red;
  transition: all 0.3s ease-out;
}

.btn---wide {
  padding: 12px 64px;
}

.btn--large:hover,
.btn--medium:hover,
.btn--mobile:hover {
  transition: all 0.3s ease-out;
  background: $White;
  color: $Dark-pruple;
}

.primary {
  background: $Red;
  color: $White;
  border: none;
  text-align: center;
  &:hover {
    background: $White;
    color: $Red;
    border: none;
  }
}

.green {
  background: greenyellow;
  color: #fff;
  border: none;
  &:hover {
    background: #d84c92;
  }
}

.red {
  background: crimson;
  color: #fff;
  border: none;
  &:hover {
    background: #d84c92;
  }
}
