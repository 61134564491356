.main-div {
  background-color: #1d134c;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.coming-soon-title {
  color: #ffffff;
  font-size: 40px;
  margin-top: 70px;
  font-weight: 400;
  padding: 0;
}

@keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(0px);
  }
}

.coming-soon-image {
  box-sizing: border-box;
  overflow: hidden;
  transform: translatey(0px);
  animation: float 6s ease-in-out infinite;
  img {
    width: 100%;
    height: auto;
  }
}

.social-links {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
.social-links a {
  margin: 0 10px;
  transition: all 0.3s ease-in-out;
}
.social-links img {
  width: 30px;
  height: 30px;
}
