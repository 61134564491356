@font-face {
  font-family: "Poppins-Black";
  src: local("Poppins-Black"),
    url("../Font/Poppins-Black.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Poppins-ExtraBold";
  src: local("Poppins-ExtraBold"),
    url("../Font/Poppins-ExtraBold.ttf") format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "Poppins-Bold";
  src: local("Poppins-Bold"), url("../Font/Poppins-Bold.ttf") format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "Poppins-SemiBold";
  src: local("Poppins-SemiBold"),
    url("../Font/Poppins-SemiBold.ttf") format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "Poppins-Regular";
  src: local("Poppins-Regular"),
    url("../Font/Poppins-Regular.ttf") format("truetype");
  font-weight: normal;
}
