//BRAND COLORS
$Dark-pruple: #130d33;
$Blue: #55d6fd;
$Purple: #1d134c;
$Yellow: #ffec82;
$Pink: #ee84b2;
$light-pink: #fcc6cf;
$Red: #d3294b;
$White: #ffff;

.posts-view {
  width: 100%;
  height: max-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .posts-view-wrapper {
    height: max-content;

    width: 80%;
    margin-top: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h1 {
      text-align: center;
    }
  }
  .post-title-ctnr {
    width: 100%;
    text-align: center;
    h1,
    h2,
    h3 {
      margin-bottom: 0.8rem;
    }
    p {
      padding: 15px 15px;
    }
    .post-info {
      width: 90%;
      display: flex;
      flex-flow: row wrap;
      //gap: 30px;
      justify-content: flex-start;
      align-items: center;
      margin: auto;
      div {
        p {
          display: inline;
        }
      }
    }
  }
}

.post-content-ctnr {
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .hero-image {
    width: 100%;
    max-width: 1920px;
    margin: 0 0;
    img {
      width: 100%;
    }
  }
  .post-ctnr-inner {
    width: 60%;
    padding: 3rem;
    margin-top: 2rem;
    .post-image {
      width: 100%;
      margin: 2rem 0;
      img {
        width: 100%;
      }
    }
    .post-section-title {
      margin: 30px 0;
      width: 100%;
      //color: $Pink;
    }
    .inner-title {
      //color: $Red;
    }
    .post-paragraph {
      margin: 20px 0;
      width: 100%;
    }
  }
  .post-footer {
    width: 60%;
    padding-bottom: 2rem;
    text-align: center;
    height: max-content;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    p {
      text-align: center;
    }
  }
}
.column-ctnr {
  display: flex;
  width: 100%;
  //background: cyan;
  flex-flow: row wrap;
  gap: 20px;
  margin-top: 30px;
  justify-content: space-around;
  align-items: center;
  p {
    font-size: 1rem;
  }
  .column-section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    //background: cornflowerblue;
    height: 100%;
    margin-top: 10px;
    min-width: 230px;
    max-width: 40%;
    h3 {
    }
    p {
      padding-right: 10px;
    }
    .column-title {
      color: $Pink;
    }
  }
}

///////////////////////////////////////////////////////////////

.grid-container {
  display: grid;
  grid-template-columns: 1.5fr 0.5fr;
  grid-template-rows: 0.1fr 0.1fr 1fr;
  gap: 15px 15px;
  grid-auto-flow: row;
  grid-template-areas:
    "posts-ctnr tags-ctnr"
    "posts-ctnr ."
    "posts-ctnr .";
}

.posts-ctnr {
  //background: cyan;
  grid-area: posts-ctnr;
}

.post-tags-ctnr {
  height: 500px;
  overflow: hidden;
  border-bottom: 1px solid rgb(131, 131, 131);
  grid-area: tags-ctnr;
}

.contact-ctnr {
  height: 600px;
  overflow: hidden;
  border-bottom: 1px solid cyan;
  grid-area: contact-ctnr;
}

////////////////////////////////////////////////////////////////////////////

@media screen and (max-width: 960px) {
  .posts-view-wrapper {
    height: max-content;
    width: 100%;
    .post-info {
      width: 100%;
      //background: cornflowerblue;
      display: flex;
      flex-flow: row wrap;
      //gap: 30px;
      justify-content: flex-start;
      align-items: center;
      margin: auto;
      div {
        p {
          display: inline;
        }
      }
    }
  }

  .post-content-ctnr {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .hero-image {
      width: 100%;
      margin: 25px 0;
      img {
        width: 100%;
      }
    }
    .post-ctnr-inner {
      width: 100%;
      padding: 3rem;
      margin-top: 0;
      .post-image {
        width: 100%;
        margin: 25px 0;
        img {
          width: 100%;
        }
      }
      .post-section-title {
        width: 100%;
        color: white;
      }
      .post-paragraph {
        width: 100%;
      }
    }
  }

  .column-ctnr {
    display: flex;
    width: 100%;
    //background: cyan;
    flex-flow: row wrap;
    gap: 20px;
    margin-top: 30px;
    justify-content: space-around;
    align-items: center;
    p {
      font-size: 1rem;
    }
    .column-section {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      //background: cornflowerblue;
      height: 100%;
      margin-top: 10px;
      min-width: 230px;
      max-width: 100%;
      h3 {
      }
      p {
        padding-right: 10px;
      }
      .column-title {
        color: $Pink;
      }
    }
  }
}
