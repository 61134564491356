//BRAND COLORS
$Dark-pruple: #130d33;
$Blue: #55d6fd;
$Purple: #1d134c;
$Yellow: #ffec82;
$Pink: #ee84b2;
$Red: #d3294b;
$White: #ffff;

.nav {
  width: 100%;
  position: fixed;
  color: $White;
  text-transform: uppercase;
  z-index: 7;
  font-family: "Poppins-SemiBold";
}
/* NAVIGATION LAYOUT */
// .desktop-nav {
//   width: 100%;
//   height: 100%;
// }
.center-nav {
  width: 100%;
  overflow: hidden;
  position: fixed;
  z-index: 30;
  top: 0px;
  left: 0px;

  ul {
    transition: all 0.4s ease-in-out;
    padding: 30px 30px 20px 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .center-nav-li {
      display: flex;
      &:first-child {
        align-self: flex-start;
        display: block;
        margin-right: auto;
      }
      &:nth-child(2) {
        position: fixed;
        text-align: center;
      }
      &:last-child {
        align-self: flex-end;
        margin-left: auto;
      }
    }
  }

  .logo-img {
    width: 40px;
    padding: 0;
    img {
      width: 100%;
    }
  }
}
.opaque {
  background: $Dark-pruple;
}
.side-nav {
  //width: 100%;
  padding: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  position: fixed;
  top: 45%;
  left: 0px;
  ul {
    position: relative;
  }
  .side-nav-li {
    display: flex;
    &:first-child {
      position: absolute;
      transform: rotate(-90deg);
      &:hover {
        transform: rotate(-90deg) scale(1.3);
      }
    }

    &:last-child {
      position: absolute;
      left: 93vw;
      transform: rotate(90deg);
      &:hover {
        transform: rotate(90deg) scale(1.3);
      }
    }
  }
}

.nav-links {
  display: block;
}
.mobile-nav {
  display: none;
}
@media screen and (max-width: 960px) {
  .logo-img {
    width: 40px;
    padding: 0;
    img {
      width: 100%;
    }
  }
  .mobile-nav {
    display: flex;
  }

  .center-nav {
    display: none;
  }
  .side-nav {
    display: none;
  }
  .content {
    width: 55%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    top: 25px;
    left: 25px;
    z-index: 20;
    &:last-child {
      position: fixed;
      text-align: center;
    }
  }
  .burger-input {
    opacity: 0;
  }
  .burger-input + .burger-label {
    position: fixed;
    top: 40px;
    right: 40px;
    height: 20px;
    width: 15px;
    z-index: 5;
    .burger-span {
      position: absolute;
      width: 100%;
      height: 2px;
      top: 50%;
      margin-top: -1px;
      left: 0;
      display: block;
      background: $Pink;
      transition: 0.5s;
    }
    .burger-span:first-child {
      top: 3px;
    }
    .burger-span:last-child {
      top: 16px;
    }
  }
  .burger-label:hover {
    cursor: pointer;
  }
  .burger-input:checked + .burger-label {
    .burger-span {
      opacity: 0;
      top: 50%;
    }
    .burger-span:first-child {
      opacity: 1;
      transform: rotate(405deg);
    }
    .burger-span:last-child {
      opacity: 1;
      transform: rotate(-405deg);
    }
  }
  .burger-input ~ nav {
    background: $Purple;
    color: $White;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 85px;
    z-index: 3;
    transition: 0.3s;
    transition-delay: 0.3s;
    overflow: hidden;
    > ul {
      text-align: center;
      position: absolute;
      top: 35%;
      left: 20%;
      right: 20%;
      > li {
        opacity: 0;
        transition: 0.3s;
        transition-delay: 0s;
        > a {
          text-decoration: none;
          text-transform: uppercase;
          color: $White;
          font-family: sans-serif;
          display: block;
          padding: 30px;
        }
      }
    }
  }

  .burger-input:checked ~ nav {
    height: 100%;
    overflow-y: scroll;
    transition-delay: 0s;
    scrollbar-width: none;
    > ul {
      > li {
        opacity: 1;
        transition-delay: 0.3s;
      }
    }
  }
}
