@import url("../components/FontFaces.css");

//BRAND COLORS
$Dark-pruple: #130d33;
$Blue: #55d6fd;
$Purple: #1d134c;
$Yellow: #ffec82;
$Pink: #ee84b2;
$Red: #d3294b;
$White: #ffff;
a {
  color: $White;
  text-decoration: none;
  cursor: pointer;
}

link {
}

hr {
  margin: 1.5rem 0;
  border: 1px solid $Pink;
}

h1 {
  font-size: clamp(4rem, 10vw, 7rem);
  font-weight: 900;
  margin-bottom: 1rem;
  line-height: 1.2;
  letter-spacing: 0.2rem;
  font-family: "Poppins-Bold";
}
h2 {
  font-size: 3rem;
  line-height: 1.1;
}

h3 {
  font-size: 1.7rem;
  line-height: 1.1;
}

p {
  text-align: left;
  font-size: 1.1em;
  margin-top: 10px;
}

ul {
  list-style: none;
}
label,
input,
textarea {
  font-family: "Poppins-Regular";
  color: $White;
  font-size: 1rem;
  display: block;
  &::placeholder {
    color: $Purple;
  }
}
.section-title {
  font-family: "Poppins-SemiBold";
}

.main-ctnr {
  width: 100%;
  background: $Dark-pruple;
  height: max-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  scroll-behavior: smooth;
}
.content-ctnr {
  width: 80%;
  height: max-content;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer {
  width: 100%;
  height: 30vh;
  background: $Purple;
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 8rem;

  .footer-content-ctnr {
    width: 100%;
    div {
      width: max-content;

      margin-top: 20px;
    }
  }
}
.section-ctnr {
  width: 100%;
  height: max-content;
}
.section-content {
  width: 100%;
  min-height: 50vh;
  height: max-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-top: 8rem;
  p {
    max-width: 90%;
  }
}
.paragraph-ctnr-main {
  height: 60%;
  //background: cornflowerblue;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 8rem;

  .paragraph-ctnr-inner {
    width: 60%;
    .column-ctnr {
      display: flex;
      width: 100%;
      flex-flow: row wrap;
      gap: 20px;
      margin: 30px 0;
      align-items: flex-start;
      p {
        font-size: 1rem;
      }
      .column-section {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        min-width: 230px;
        max-width: 30%;
        h3 {
          //min-height: 120px;
        }
        p {
          width: 100%;
        }
        .column-title {
          color: $Red;
        }
      }
    }
  }

  .icons-list {
    //background: #55d6fd;
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    align-items: center;
    height: max-content;
    width: 90%;
    .icon-item {
      margin-right: 20px;
    }
    .icon-img {
      width: 3rem;
    }
  }
}

#Home {
  text-align: left;
  width: 100%;
  height: 100vh;
  min-height: 1000px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  .particles-bkg {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }
  .Home-content {
    width: 80%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-top: 14rem;
    h1 {
      margin-bottom: 10px;
      &::after {
        content: "";
        border-radius: 50%;
        display: inline-block;
        border-right-color: $Pink;
        border-top-color: $White;
        border-bottom-color: $Pink;
        border-left-color: $White;
        margin-left: 5px;
        border-width: 13px;
        border-style: solid;
        -webkit-transition: transform 0.7s ease-in-out;
        transition: transform 0.7s ease-in-out;
        height: 0px;
        width: 0px;
      }
      &:hover::after {
        content: "";
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }
  }
}
#About {
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    margin-bottom: 20px;
  }
  .column-section {
    width: 100%;
  }
  .column-title {
    min-height: 4rem;
  }

  .more-info {
    .column-section {
      width: max-content;
    }
    .column-title {
      min-height: 2rem;
    }
  }
  div.timeline-ctnr {
    width: 700px;
    display: block;
    padding: 10px;
    box-sizing: border-box;
    //margin: 30 auto;

    .time-line-list {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      align-items: flex-end;
      list-style: none;
      width: 100%;
      border-bottom: 4px solid $White;
      box-sizing: border-box;

      .time-line-item {
        margin: 0;
        padding: 0;
        border-left: 2px solid $Pink;
        border-image: linear-gradient(
          -90 deg,
          #d683af 30%,
          rgba(255, 255, 255, 0) 60%
        );

        position: relative;
        line-height: 1;

        &::before {
          position: absolute;
          content: "";
          width: 14px;
          height: 14px;
          background-color: $Pink;
          bottom: 0;
          left: 0;
          transform: translateX(-60%) translateY(50%);
          border-radius: 50%;
        }

        .year-event {
          color: $Red;
          padding: 5px;
          padding-bottom: 0;
        }

        .event-desc {
          padding: 3px;
          max-width: 90%;
        }
      }
    }
  }
  /////////////////////////////////new timeline
  .timeline {
    margin: 0 auto;
    max-width: 100%;

    //padding: 25px;
    display: grid;
    grid-template-columns: 1fr 3px 1fr;
    font-family: "Fira Sans", sans-serif;
    color: #fff;
  }

  .timeline__component {
    margin: 0 10px 30px 10px;
  }

  .timeline__component--bg {
    padding: 0.8em;
    //background: rgba(255, 255, 255, 0.2);
    //box-shadow: 0 0 4px #ee84b2;
    border: 1px solid #ee84b2;
    border-radius: 10px;
  }

  /* LEAVE TILL LAST */
  .timeline__component--bottom {
    margin-bottom: 0;
  }

  .timeline__middle {
    position: relative;
    background: #d6d6d6;
  }

  .timeline__point {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 15px;
    height: 15px;
    background: $Red;
    border-radius: 50%;
  }

  /* LEAVE TILL LAST */
  .timeline__point--bottom {
    top: initial;
    bottom: 0;
  }

  .timeline__date--right {
    text-align: right;
    color: #106fb4;
  }

  .timeline__title {
    margin: 0;
    font-size: 1.15em;
    font-weight: bold;
    color: #ee84b2;
  }

  .timeline__paragraph {
    line-height: 1.5;
  }

  .timeline__date {
    color: $Red;
  }

  .titol {
    font-style: italic;
  }
}
#Contact {
  width: 100%;
  text-align: left;
  height: max-content;
  .column-ctnr {
    flex-flow: row nowrap;
    .column-title {
      min-height: 4rem;
    }
  }
  .contact-form {
    display: flex;
    flex-direction: column;
    margin: 30px 0;

    input,
    textarea {
      width: 100%;
      //border: 1px solid rgba(255, 255, 255, 0.5);
      border: none;
      background: rgba(255, 255, 255, 0.3);

      border-radius: 5px;
      margin: 10px 0;
      padding: 5px;
    }
    input {
      height: 40px;
    }
    label {
      margin-left: 6px;
    }
    .submit-btn {
      float: right;
      align-self: center;
      margin: 20px 0;
    }
  }
}

/*PEOJECTS PAGE */
.projects-view {
  height: max-content;
  h1 {
    line-height: 0.9;
  }
}

.recent-projects {
  width: 100%;
  height: max-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: visible;
}
/*
.other-projects {
  height: 200px;
  width: 100%;
  margin-top: 5rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
  text-align: center;
  overflow: hidden;

  .recent {
    height: 200px;
    box-sizing: content-box;
  }

  .centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}*/

.project {
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  margin: 5rem 0 5rem 0;
  .p-desc-container {
    width: 30%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-right: 30px;
    .p-tags {
      color: $Red;
    }
  }
  .p-img-container {
    width: 70%;
    height: 35rem;
    display: flex;
    flex-direction: row;
    align-items: center;

    .figure-img-ctnr {
      width: 100%;
      height: 35rem;
      overflow: hidden;
      max-width: 100%;
      max-height: 100%;
      background: $Purple;

      &:hover .p-img {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
      }
      .p-img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: 50% 50%;
        -webkit-transform: scale(1.2);
        transform: scale(1.2);
        -webkit-transition: 0.3s ease-in-out;
        transition: 0.3s ease-in-out;
        opacity: 0.4;
      }
    }
  }
}
.reversed {
  flex-direction: row-reverse;
  .p-desc-container {
    padding-right: 0;
    padding-left: 30px;
    justify-content: flex-end;

    p {
      padding: 0;
    }
  }
}
/*****************************************************************/

@media screen and (max-width: 960px) {
  h2 {
    font-size: 2rem;
  }
  #Home {
    text-align: left;
    padding-top: 60px;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    //background: rgb(233, 91, 124);
    h1 {
      font-family: "Poppins-Bold";
      line-height: 1;
    }

    .Home-content {
      width: 80%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      h1 {
        &::after {
          content: ".";
          color: $Pink;
          border: none;
        }

        &:hover::after {
          content: ".";
          -webkit-transform: none;
          transform: none;
        }
      }

      p {
        max-width: 100%;
      }
    }
  }
  #About {
    height: max-content;
    p {
      max-width: 100%;
    }
  }

  #Contact {
    width: 100%;
    text-align: left;
    height: max-content;
    .contact-specialties {
      display: none;
    }
    .contact-form {
      display: flex;
      flex-direction: column;
      height: 100%;

      input,
      textarea {
        width: 100%;

        border: none;
        background: rgba(255, 255, 255, 0.3);

        border-radius: 5px;
        margin-bottom: 30px;
        padding: 5px;
      }
      input {
        height: 50px;
      }
      label {
        margin-left: 6px;
      }
      .submit-btn {
        float: right;
        align-self: center;
      }
    }
  }

  .paragraph-ctnr-main {
    height: 60%;
    width: 100%;
    display: flex;
    align-items: flex-start;
    padding: 0;

    .paragraph-ctnr-inner {
      width: 100%;
      .column-ctnr {
        display: flex;
        width: 100%;
        flex-flow: row wrap;

        //justify-content: center;
        //align-items: center;
        p {
          font-size: 1rem;
        }
        .column-section {
          min-width: 20%;
          max-width: 100%;
          margin-bottom: 20px;

          h3 {
            min-height: 10px;
            margin-bottom: 10px;
          }
          .column-title {
            color: $Red;
          }
        }
      }
    }
  }
  .icons-list {
    display: flex;
    flex-direction: row;
    margin-top: 30px;
    align-items: center;
    width: 100%;

    .icon-item {
      margin-right: 20px;
    }
    .icon-img {
      width: 2rem;
    }
  }

  /*PEOJECTS PAGE */
  .projects-view {
    height: max-content;
    overflow: hidden;
  }

  .recent-projects {
    z-index: 6;
    width: 100%;
    height: max-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: visible;
  }

  .project {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin: 3rem 0 3rem 0;

    .p-desc-container {
      width: 100%;
      height: max-content;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0;
      margin-bottom: 1rem;
      align-items: flex-start;
      //background: cornflowerblue;
    }
    .p-img-container {
      width: 100%;
      height: 20rem;
      display: flex;
      flex-direction: row;
      justify-content: center;
      opacity: 1;
      .figure-img-ctnr {
        width: 100%;
        height: 20rem;
        overflow: hidden;
        max-width: 100%;
        max-height: 100%;
        background: $Purple;
        .p-img {
          height: 100%;
        }
      }
    }
  }

  .reversed {
    flex-direction: column;
    .p-desc-container {
      padding: 0;
      //justify-content: flex-end;
      p {
        padding: 0;
      }
    }
  }

  /*  .other-projects {
    height: 20rem;
    width: 100%;
    margin-top: 5rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: relative;
    text-align: center;
    overflow: hidden;

    .p-img-ctnr {
      background: crimson;
      .recent {
        opacity: 0.6;
        box-sizing: content-box;
      }
    }

    .centered {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }*/
}
